import axios from 'axios'
import { getAccessToken } from './auth'

// const BASE_URL = 'https://oauth2.vincss.dev/v1'

export {
getDashboardLogs, getKeysConfig, getApplicationConfig,
    editApplication, getChallenge, sendWebAuthnResponse,
}

function getDashboardLogs () {
    const url = '/v1/api/keys/dashboard'
    return axios.get(url, {
        withCredentials: false,
    }).then(response => response.data)
}

function getApplicationConfig () {
    const url = '/v1/api/apps'
    return axios.get(url, {
        headers: { Authorization: `Bearer ${getAccessToken()}` },
        withCredentials: false,
    }).then(response => response.data)
}

export function getApplicationSecret (data) {
    const url = `/v1/api/apps/secret/${data.ClientId}`
    return axios.get(url, {
        headers: { Authorization: `Bearer ${getAccessToken()}` },
        withCredentials: false,
    }).then(response => response.data)
}

function getKeysConfig () {
    const url = '/v1/api/keys'
    return axios.get(url).then(response => response.data)
}

export function getAppsStats (data) {
  const url = `/v1/api/stats/apps/${data.id}/${data.start}/${data.end}`
  return axios.get(url, {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
    withCredentials: false,
  }).then(response => response.data)
}

export function getKeysStats (data) {
  const url = `/v1/api/stats/keys/${data.start}/${data.end}`
  return axios.get(url, {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
    withCredentials: false,
  }).then(response => response.data)
}
function editApplication (data) {
    const url = `/v1/api/apps/update/${data.ClientId}`

    return axios.put(url,
        data, {
            headers: { Authorization: `Bearer ${getAccessToken()}`, 'Content-Type': 'application/json' },
            withCredentials: false,
        }).then(response => response.data)
}

export function addApplication (data) {
    const url = '/v1/api/apps/add'
    return axios.post(url,
        data, {
            headers: { Authorization: `Bearer ${getAccessToken()}`, 'Content-Type': 'application/json' },
            withCredentials: false,
        }).then(response => response.data)
}

export function refreshTokenData (data) {
  const url = '/v1/auth/token'
  return axios.post(url,
    data, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: false,
    }).then(response => response.data)
}
export function deleteApplication (data) {
    const url = `/v1/api/apps/delete/${data.ClientId}`
    return axios.delete(url,
        {
            headers: { Authorization: `Bearer ${getAccessToken()}`, 'Content-Type': 'application/json' },
            withCredentials: false,
        }).then(response => response.data)
}

export function editKey (data) {
    const url = `/v1/api/keys/update/${data.keyID}`

    return axios.put(url,
        data, {
            headers: { Authorization: `Bearer ${getAccessToken()}`, 'Content-Type': 'application/json' },
            withCredentials: false,
        }).then(response => response.data)
}
export function deleteKey (data) {
    const url = `/v1/api/keys/delete/${data.keyID}`
    return axios.delete(url,
        {
            headers: { Authorization: `Bearer ${getAccessToken()}`, 'Content-Type': 'application/json' },
            withCredentials: false,
        }).then(response => response.data)
}

function getChallenge (attestationOrAssertion, data) {
    const url = `/v1/${attestationOrAssertion}/options`
    return axios.post(url,
        data, {
            headers: { Authorization: `Bearer ${getAccessToken()}`, 'Content-Type': 'application/json' },
            withCredentials: false,
        }).then(response => response.data)
}

export function getRegisterUserChallenge (attestationOrAssertion, data) {
    const url = `/v1/reguser/${attestationOrAssertion}/options`
    return axios.post(url,
        data, {
            headers: { Authorization: `Bearer ${getAccessToken()}`, 'Content-Type': 'application/json' },
            withCredentials: false,
        }).then(response => response.data)
}
export function sendRegisterUserWebAuthnResponse (attestationOrAssertion, data) {
    const url = `/v1/reguser/${attestationOrAssertion}/result`
    return axios.post(url,
        data, {
            headers: { Authorization: `Bearer ${getAccessToken()}`, 'Content-Type': 'application/json' },
            withCredentials: false,
        }).then(response => response.data)
}
function sendWebAuthnResponse (attestationOrAssertion, data) {
    const url = `/v1/${attestationOrAssertion}/result`
    return axios.post(url,
        data, {
            headers: { Authorization: `Bearer ${getAccessToken()}`, 'Content-Type': 'application/json' },
            withCredentials: false,
        }).then(response => response.data)
}
