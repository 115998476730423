<template>
  <v-container
    id="register"
    tag="section"
    :class="`${(showRegis) ? 'fill-height justify-center' : 'justify-center'}`"
    style="max-width: 950px"
  >
    <v-card
      v-if="!allowRegister"
      color="rgba(100, 100, 100, 0)"
      outlined
    >
      <v-card-title class="justify-center py-3">
        <span class="headline">Register Maintenance</span>
      </v-card-title>
      <h1>Hiện tại, hệ thống đang tạm khóa chức năng tự đăng ký, quý khách hàng vui lòng liên hệ qua email <a href="mailto:sales@vincss.net">sales@vincss.net</a> để được hỗ trợ</h1>
    </v-card>

    <v-card
      v-else-if="showRegis"
      color="rgba(100, 100, 100, 0)"
      outlined
    >
      <v-card-title class="justify-center py-3">
        <span class="headline">Register</span>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              md="5"
              sm="12"
            >
              <v-text-field
                v-model="newUser.username"
                label="Username *"
                prepend-icon="mdi-account-outline"
                :rules="[rules.username.notEmpty, rules.username.characters]"
                :counter="20"
              />
            </v-col>

            <v-col md="1" />

            <v-col
              cols="12"
              md="6"
              sm="12"
            >
              <v-text-field
                v-model="newUser.fullName"
                label="Full Name *"
                prepend-icon="mdi-account-tie-outline"
                :rules="[rules.fullName.notEmpty, rules.fullName.characters, rules.fullName.length]"
                :counter="30"
              />
            </v-col>
          </v-row>

          <v-text-field
            v-model="newUser.email"
            label="Email *"
            prepend-icon="mdi-email-outline"
            :rules="[rules.email.notEmpty, rules.email.characters]"
            :counter="30"
          />

          <v-text-field
            v-model="newUser.keyName"
            label="Key Name *"
            prepend-icon="mdi-key-outline"
            :rules="[rules.keyName.notEmpty]"
            :counter="40"
          />

          <v-row no-gutters>
            <v-col
              cols="12"
              md="5"
              sm="12"
            >
              <v-text-field
                v-model="newUser.groupName"
                label="Group (Optional)"
                prepend-icon="mdi-account-group-outline"
                :rules="[rules.group.characters]"
                :counter="10"
              />
            </v-col>
            <v-col md="1" />
            <v-col
              cols="12"
              md="6"
              sm="12"
            >
              <v-text-field
                v-model="newUser.phoneNumber"
                label="Phone (Optional)"
                prepend-icon="mdi-phone-outline"
                :rules="[rules.phone.characters]"
                :counter="13"
              />
            </v-col>
          </v-row>

          <v-radio-group
            v-model="newUser.regType"
            row
            class="text-center"
          >
            <v-radio
              label="FIDO2 Key register"
              value="FIDO2"
            />
            <v-radio
              label="QR register"
              value="QRCode"
            />
          </v-radio-group>
          <v-row
            class="align-center"
            no-gutters
          >
            <v-checkbox
              v-model="regAgree"
              :rules="[v => !!v || 'You must agree to continue']"
              required
              class="mb-1"
            >
              <!--              <template v-slot:label>
                <span>I agree to the &nbsp;</span>
              </template>-->
            </v-checkbox>

            <span
              class="label"
            >
              I agree to the &nbsp;&nbsp;&nbsp;
              <a
                class="secondary--text ml-1"
                :href="tncURL"
                target="frame_tnc"
                @click="showTnC"
              >
                Terms and Conditions
              </a>
              <v-spacer />
            </span>
          </v-row>
        </v-form>

        <v-row class="justify-center">
          <v-spacer />
          <v-btn
            class="btn-back"
            outlined
            @click="resetForm"
          >
            Clear
          </v-btn>
          <v-btn
            class="btn-next"
            outlined
            @click="register"
          >
            OK
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      v-else
      color="rgba(100, 100, 100, 0)"
      outlined
      style="width: 100%"
    >
      <!--<div v-html="tncURL" />-->
      <iframe
        src=""
        style="height: 500px; width:100%; border: 0; background-color: white"
        name="frame_tnc"
      />
      <a
        href="#"
        style="color: white; font-size: 17px;"
        @click="showRegis = true"
      >
        Close
      </a>
    </v-card>

    <v-dialog
      v-model="showQR"
      persistent
      max-width="350px"
    >
      <v-card
        color="rgba(255, 255, 255)"
        outlined
      >
        <v-card-text style="color: #333333">
          <div class="text-h3 subtitle-1 font-weight-light text-center">
            Scan the QR code
          </div>

          <v-img :src="imgqr" />

          <v-row class="text-center">
            <v-col
              md="4"
              sm="4"
              lg="4"
            />
            <v-col
              md="4"
              sm="4"
              lg="4"
            >
              <v-btn
                width="100%"
                color="success"
                @click="cancelQR"
              >
                Cancel {{ countDown }}
              </v-btn>
            </v-col>
            <v-col
              md="4"
              sm="4"
              lg="4"
            />
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { VueReCaptcha } from 'vue-recaptcha-v3'
  import {
    preformatMakeCredReq,
    publicKeyCredentialToJSON,
  } from '../../utils/helpers'
  import { sendRegisterUserWebAuthnResponse } from '../../utils/user-api'
  import LocalStorageService from '../../utils/auth'
  import Vue from 'vue'
  /* global RECAPTCHA_SITE_KEY */
  Vue.use(VueReCaptcha, {
    siteKey: RECAPTCHA_SITE_KEY,
  })
  // const EventSource = NativeEventSource || EventSourcePolyfill
  const localStorageService = LocalStorageService.getService()
  let msgServer
  export default {
    name: 'PagesLogin',
    data: function () {
      return {
        valid: true,
        /* global ALLOW_REGISTER */
        allowRegister: ALLOW_REGISTER,
        showRegis: true,
        tncURL: '/tc',
        newUser: {
          username: '',
          fullName: '',
          email: '',
          keyName: '',
          regType: 'FIDO2',
          groupName: '',
          phoneNumber: '',
        },
        countDown: 40,
        regAgree: false,
        showQR: false,
        termsDialog: false,
        urls: {
          register: '/v1/api/users',
          QRsse: '/v1/register/events',
        },
        imgqr: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADIAQMAAACXljzdAAAABlBMVEX///8AAABVwtN+AAAD10lEQVR4nOzYPY4sJxcG4JevPokM7MxByeVlENhiS1Whk67qDUxv6QRXYhtl1QYgI0C8Fv3jO3fslu4wE16ibj39Q8PhnEPjx/j0ocnAwAsZsNI4bl6RsVegAtyUxAZuWpFVK8HcK1MSCman5LgwYnjxKpQPSIAfZw8/2hzdqMIHpbp9zdBMwXiePyRQoWI3LgUsQYVhc69W591CBuOOF5fCuNwffd2590obicfmqsfJA+OsX8XPu2U4i81DRdWkJOEG+CP2ylF9dSO08cc5MBR44Lqinyu6WFa3w6sWo9WPpzaX2CkY4Y0vxkUPo40vKhg99IrGmqMfLjSaifC7Fct97hSwwnJfg9EFsFJsNh7dMiVWvZ88hZszfl/E8tg6RZdZpzwuAXq60EpZA9xtDXpkXIU8LjR+Stn40dLgA2JgNIymDC8arsywYdh6hecQcbz46MoabICVqse5V8aTgx/hkwwbUsbJqYxeAVZaYmFLjNej0gIInaIZffUwrrr95JKMgLnvz0Ou7//5uwTHWWwYWO7F7Zq072f7U2WcXQRjm8EqE6czLW8Z6asMFU/E5m9EwwrD1DZ+ilCE8ffz80r8/kxW+UbA9hlTKgAFP+nhnB/57ZVs/y2/vRVNspUE+UVxa685C3mP0buYp6L/JWXWFeP6ZTgTMDheEPW3ov56Jngr2JeQMmzB0mbtRoMU3sifT+WPN6IZdXRD/D+ma4EZgagLOgXjSuCI7iq/ArMmb/vzkP89FcU30uqGCkPKaGLluvmPWd9lfSrTW8HClMv8O2zbn3wkVnePqodo4omot1JmRLSuBidgEqzB/JN7H4LvlzWrMC5fHmdO5UeP1CGYXnwK+3L7HjgmMW7/sFjuxr8WXVSI7qAYHGdW1yrg4/d0CFvTSkrVbUmxr1TsFpS2uBOZ8m5JljUw32K0R1h11EeTqerqmaTec2+PXEOAFUqGFFKYzpLC7Sz0yHhq5eoSEodL642XwFYc+kRP56w4bS76smbjypJtvvfxPbLBtNuIZeuNw0R51PoeGZdscFwE7oiO5Kat3GpJh+CoUBxP7fmFSVo3KPvcKXq0Icm1CkyU6w1FhX5hdIm7ATm0grkvweCR394tgBKVyyotqEVlUnjvez9ZoGtrFlvelKqLYrz38R3Sbj1GH+3KOK5MYTc6hVuM9giUWBk2D42TgyurRH3rr3tkShL9cP1DICJqGEd+RIKSAk8ZVbvH28BwW4M+Eeh9ydFh9krKKtXvc69ASeLAbDAuUtEO+L2H7ZG2Px7QNuwnRznOQnl0T++WH6Nn/B0AAP//7hw3VCp2XD0AAAAASUVORK5CYII=',
        rules: {
          username: {
            characters: value => {
              const pattern = /^(?=[a-zA-Z0-9._]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/
              return pattern.test(value) || 'Invalid username'
            },
            notEmpty: v => !!v || 'Required',
          },
          fullName: {
            characters: value => {
              // const v = value.replace(/^\s+|\s+$/gm, '')
              const pattern = /^(?=[a-zA-Z0-9 ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\u0065-\u0309\u0065-\u0301\u0065-\u0300\u0065-\u0323\u0065-\u0303\u00EA-\u0309\u00EA-\u0301\u00EA-\u0300\u00EA-\u0323\u00EA-\u0303\u0079-\u0309\u0079-\u0301\u0079-\u0300\u0079-\u0323\u0079-\u0303\u0075-\u0309\u0075-\u0301\u0075-\u0300\u0075-\u0323\u0075-\u0303\u01B0-\u0309\u01B0-\u0301\u01B0-\u0300\u01B0-\u0323\u01B0-\u0303\u0069-\u0309\u0069-\u0301\u0069-\u0300\u0069-\u0323\u0069-\u0303\u006F-\u0309\u006F-\u0301\u006F-\u0300\u006F-\u0323\u006F-\u0303\u01A1-\u0309\u01A1-\u0301\u01A1-\u0300\u01A1-\u0323\u01A1-\u0303\u00F4-\u0309\u00F4-\u0301\u00F4-\u0300\u00F4-\u0323\u00F4-\u0303\u0061-\u0309\u0061-\u0301\u0061-\u0300\u0061-\u0323\u0061-\u0303\u0103-\u0309\u0103-\u0301\u0103-\u0300\u0103-\u0323\u0103-\u0303\u00E2-\u0309\u00E2-\u0301\u00E2-\u0300\u00E2-\u0323\u00E2-\u0303\u0045-\u0309\u0045-\u0301\u0045-\u0300\u0045-\u0323\u0045-\u0303\u00CA-\u0309\u00CA-\u0301\u00CA-\u0300\u00CA-\u0323\u00CA-\u0303\u0059-\u0309\u0059-\u0301\u0059-\u0300\u0059-\u0323\u0059-\u0303\u0055-\u0309\u0055-\u0301\u0055-\u0300\u0055-\u0323\u0055-\u0303\u01AF-\u0309\u01AF-\u0301\u01AF-\u0300\u01AF-\u0323\u01AF-\u0303\u0049-\u0309\u0049-\u0301\u0049-\u0300\u0049-\u0323\u0049-\u0303\u004F-\u0309\u004F-\u0301\u004F-\u0300\u004F-\u0323\u004F-\u0303\u01A0-\u0309\u01A0-\u0301\u01A0-\u0300\u01A0-\u0323\u01A0-\u0303\u00D4-\u0309\u00D4-\u0301\u00D4-\u0300\u00D4-\u0323\u00D4-\u0303\u0041-\u0309\u0041-\u0301\u0041-\u0300\u0041-\u0323\u0041-\u0303\u0102-\u0309\u0102-\u0301\u0102-\u0300\u0102-\u0323\u0102-\u0303\u00C2-\u0309\u00C2-\u0301\u00C2-\u0300\u00C2-\u0323\u00C2-\u0303]+$)/u
              return pattern.test(value) || 'Invalid name'
            },
            length: v => v.length <= 30 || 'Max length is 30 characters',
            notEmpty: v => !!v || 'Required',
          },
          email: {
            characters: value => {
              const pattern = /^([A-Za-z0-9]+)(((\.|_|-)([A-Za-z0-9]+))*)@([0-9A-Za-z]+)\.([A-Za-z0-9]+)(\.([A-Za-z0-9]+)+)*$/
              return pattern.test(value) || 'Invalid email'
            },
            notEmpty: v => !!v || 'Required',
          },
          keyName: {
            notEmpty: v => !!v || 'Required',
          },
          group: {
            characters: value => {
              const pattern = /^$|^(?=[a-zA-Z0-9._]{3,10}$)(?!.*[_.]{2})[^_.].*[^_.]$/
              return pattern.test(value) || 'Invalid group name'
            },
          },
          phone: {
            characters: value => {
              const pattern = /^$|^(\+[0-9]{2}|0)([0-9]{9,10}$)/
              return pattern.test(value) || 'Invalid phone number'
            },
          },
        },
      }
    },
    watch: {
      countDown: function (n, o) {
        // console.log('countDown')
        if (n <= 0) {
          this.showQR = false
          this.countDown = 0
        }
      },

      'newUser.regType': function (n, o) {
        // console.log(n, o)
        if (this.newUser.username !== '' && this.newUser.username !== null && this.newUser.username !== undefined) {
          if (n === 'QRCode') {
            this.newUser.keyName = (this.newUser.username + '_' + 'QR' + '_' + this.rndStr(6)).toUpperCase()
          } else {
            this.newUser.keyName = (this.newUser.username + '_' + this.rndStr(9)).toUpperCase()
          }
        } else {
          this.newUser.keyName = ''
        }
      },

      'newUser.username': function (n) {
        if (n !== '' && n !== null && n !== undefined) {
          if (this.newUser.regType === 'QRCode') {
            this.newUser.keyName = (n + '_' + 'QR' + '_' + this.rndStr(6)).toUpperCase()
          } else {
            this.newUser.keyName = (n + '_' + this.rndStr(9)).toUpperCase()
          }
        } else {
          this.newUser.keyName = ''
        }
      },
    },
    /* mounted () {
      this.showQR = true
      this.countDownTimer()
    }, */
    methods: {
      async register () {
        // console.log('has errors', this.formHasErrors)
        if (!this.$refs.form.validate()) return
        this.loading = true
        const data = this.newUser
        // delete data.keyName
        // data.regType = this.newUser.regType
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded().then(() => {
          // Execute reCAPTCHA with action "login".
          this.$recaptcha('register').then((token) => {
            console.log(typeof token, token)
            console.log('no error')
            // Do stuff with the received token.
            // console.log(token)
            data.response = token
            axios.post(this.urls.register, data)
              .then(response => {
                // console.log('reg response: ', response)
                // const token = response.data.access_token
                // const user = response.data.username
                if (this.newUser.regType === 'FIDO2') {
                  this.handleFido2Response(response)
                } else {
                  this.handleQrResponse(response)
                }
              })
              .catch(e => {
                this.handleError(e)
              }).finally(() => (this.loading = false))
          }).catch(e => {
            console.log(e)
            this.handleError('Could not connect to the reCAPTCHA service.\nPlease check your internet connection and reload to get a reCAPTCHA challenge.')
          })
        }).catch(e => {
          // console.log(e)
          this.handleError('Could not connect to the reCAPTCHA service.\nPlease check your internet connection and reload to get a reCAPTCHA challenge.')
        })
      },
      async handleFido2Response (response) {
        // console.log('keyRegister')
        const makeCredChallenge = preformatMakeCredReq(response.data)
        navigator.credentials.create({ publicKey: makeCredChallenge })
          .then((response) => {
            const newCredentialInfo = publicKeyCredentialToJSON(response)
            return sendRegisterUserWebAuthnResponse('attestation', newCredentialInfo)
          }).then((response) => {
            // console.log('option resp: ', response)
            if (response.success) {
              this.show_message('success', 'Register key success. Please check email to activate user!')
              // response.status = true
              // this.table.items.push(response)this.$nextTick(function () {
              this.resetForm()
              setTimeout(() => {
                this.$router.push('/pages/login')
              }, 700)
            }
          }).catch(e => {
            this.handleError(e)
          }).finally(() => (this.loading = false))
      },

      async handleQrResponse (response) {
        this.loading = true
        this.imgqr = 'data:image/png;base64,' + response.data.data.img
        // console.log('imgqr', this.imgqr)
        this.showQR = true
        // console.log('showQR', this.showQR)
        this.countDown = 40
        this.countDownTimer()
        localStorageService.setChannel(response.data.data.token)
        // this.EventSource(`${this.urls.QRsse}/${response.data.data.token}`)
        await this.$sse(`${this.urls.QRsse}/${response.data.data.token}`, { format: 'json' })
          .then(sse => {
            // console.log(sse)

            msgServer = sse

            // console.log(sse.getSource())
            sse.onError(e => {
              // console.error('Connection lost', e)
            })

            sse.getSource().onmessage = function (event) {
              // console.log('Message on channel-1: ' + event.data)
            }

            // console.log(sse)

            // Listen for messages without a specified event
            sse.subscribe('', (data, rawEvent) => {
              // console.warn('Received a message w/o an event!', data)
            })

            // Listen for messages based on their event (in this case, "chat")
            sse.subscribe('register', (message, rawEvent) => {
              // console.log(message)
              this.show_message('success', message.keyName + ' register success. Please check email to activate user!')
              this.countDown = 0
              sse.unsubscribe('register')
              this.resetForm()
              this.showQR = false
              sse.close()
              localStorageService.clearChannel()
              setTimeout(() => {
                this.$router.push('/pages/login')
              }, 700)
            })

            // Unsubscribes from event-less messages after 7 seconds
            setTimeout(() => {
              sse.unsubscribe('')

              // console.log('Stopped listening to event-less messages!')
            }, 7000)

            // Unsubscribes from chat messages after 7 seconds
            setTimeout(() => {
              sse.unsubscribe('register')

              // console.log('Stopped listening to register messages!')
              sse.close()
              localStorageService.clearChannel()
            }, 40000)
          }).catch(e => {
            // console.log(e)
            this.handleError('QR service not available!')
          }).finally(this.loading = false)
      // storing jwt in localStorage. https cookie is safer place to store
      // localStorage.setItem('token', token)
      // localStorage.setItem('user', user)
      // axios.defaults.headers.common.Authorization = 'Bearer ' + token
      // mutation to change state properties to the values passed along
      // commit('auth_success', { token, user })
      // resolve(response)
      },

      async cancelQR () {
        // console.log('handleQRCancel')
        this.countDown = 0
        const channel = localStorageService.getChannel()
        if (channel) {
          await axios.get(`${this.urls.QRsse}/${channel}/canceladd`)
            .then(sse => {
              // console.log(sse)
              msgServer.close()
              localStorageService.clearChannel()
            }).catch(() => {
              this.show_message('error', 'No channel to cancel')
            })
        }
      },

      async showTnC () {
        this.showRegis = false
        this.tncURL = '/tc'
      },

      /* ____________________________Helpers_______________________________ */
      /* __________________________________________________________________ */
      resetForm () {
        // console.log(this.newUser)
        // console.log('before', this.$refs.form.validate())
        // this.$refs.form.reset()
        this.newUser = {
          username: '',
          fullName: '',
          email: '',
          keyName: '',
          regType: 'FIDO2',
          groupName: '',
          phoneNumber: '',
        }
        this.countDown = 40
        this.regAgree = false
        // console.log('reset', this.newUser)
        this.$refs.form.resetValidation()
      },

      countDownTimer () {
        // console.log('countDownTimer')
        if (this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1
            this.countDownTimer()
          }, 1000)
        }
      },

      rndStr (len) {
        // console.log('rndStr')
        let text = ''
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'

        for (let i = 0; i < len; i++) {
          text += chars.charAt(Math.floor(Math.random() * chars.length))
        }

        const rightNow = new Date()
        text = rightNow.toISOString().slice(0, 10).replace(/-/g, '') + '_' + text
        return text
      },

      handleError (error) {
        if (error.response === undefined) {
          this.show_message('error', error.toString())
        } else if (!error.response.data.success) {
          if (error.response.data.errorMessage) {
            this.show_message('error', error.response.data.errorMessage)
          } else if (error.response.data.validations) {
            this.show_message('error', error.response.data.validations[0].message)
          } else {
            this.show_message('error', error.response.data.message)
          }
        } else {
          this.show_message('error', 'api service not available!')
        }
      },

      show_message (type, text) {
        // type = 'success', 'info', 'error' ... color string
        this.$swal.fire({
          icon: type,
          titleText: type,
          text: text,
          timer: 5000,
        })
      },
    },
  }
</script>

<style lang="sass">
#register
  .v-card
    margin: 0
    width: 50%

  @media screen and (max-width: 767px)
    .v-card
      margin: 0
      width: 80%

  .v-btn
    margin: 12px 12px 0 0 !important
    padding: 7px 23px 7px 23px !important
    height: auto
    min-width: auto
    text-transform: none
    .v-btn__content
      font-size: 1.25rem

  .btn-next
    color: #9CCC65

  /*.btn-back
    color: #EEEEEE

  /* .v-text-field
    margin: 0 !important

  .v-messages
    flex: 1 1 auto
    font-size: 12px
    min-height: 0px
    min-width: 1px
    position: relative

  .v-input--selection-controls
    margin-top: 10px
    padding-top: 4px

  a
    font-size: 14px
    font-weight: 400
    align-items: center
    display: inline-flex
    flex: 1 1 auto
    line-height: 20px
    cursor: pointer

  .label
    font-size: 14px
    font-weight: 400
    align-items: center
    display: inline-flex
    flex: 1 1 auto
    line-height: 20px
    cursor: pointer

</style>
